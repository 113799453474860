import * as React from 'react'

import { TAG_SALE, TAG_SELLABLE } from '../products/Tags'
import PropTypes from 'prop-types'
import DotSlider from './DotSlider'

import { NewArrivalsProductSliderCard } from './NewArrivalSlider'

const RecommendedProductsSlider = ({
  heading = 'You may also like',
  products,
  className = 'relative max-w-[1366px] w-full mx-auto',
}) => {
  return (
    <section className={className}>
      <h2 className="underline-title">{heading}</h2>
      <DotSlider>
        {products.map(product => (
          <NewArrivalsProductSliderCard
            key={product.sku}
            tags={[TAG_SALE, TAG_SELLABLE]}
            product={product}
          />
        ))}
      </DotSlider>
    </section>
  )
}

RecommendedProductsSlider.propTypes = {
  heading: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          strapi_id: PropTypes.string.isRequired,
        })
      ).isRequired,
      url: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      qty: PropTypes.number.isRequired,
      small_image: PropTypes.shape({
        gatsbyImageDataMock: PropTypes.object.isRequired,
      }).isRequired,
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      special_price: PropTypes.number,
    })
  ).isRequired,
}

export default RecommendedProductsSlider
