import * as React from 'react'
import { ProductTypeHelper } from '../helpers/ProductTypeHelper'
import { gridSizes } from '../common/gatsbyImageData'
import useCDNImage from '../hooks/useCDNImage'
import IconLoaders from '../icons/IconLoaders'
import PropTypes from 'prop-types'

// Note - requires relative wrapper
const CategoryImageSimplified = ({
  image,
  product,
  Wrapper='div',
  className='aspect-square w-full h-full relative mix-blend-multiply',
  srcSizes = gridSizes,
  imgSizes = '(min-width: 1536px) 25vw, (min-width: 1024px) 33vw, 50vw',
  loading = 'lazy',
  ...props
}) => {
  product.productType = product.productType || ProductTypeHelper(product)
  const _image = useCDNImage(image, product, srcSizes, imgSizes)
  const [isLoading, setIsLoading] = React.useState(false)
  const ref = React.useRef()
  React.useEffect(() => {
    const imgElement = ref.current
    if (!imgElement || imgElement.complete) {
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    const handleLoad = () => {
      setIsLoading(false)
    }
    imgElement.addEventListener('load', handleLoad)
    return () => {
      imgElement.removeEventListener('load', handleLoad)
    }
  }, [ref.current])
  return (
    <Wrapper className={className} {...props}>
      <IconLoaders
        type={product.productType}
        className={
          'absolute inset-0 duration-200 bg-white ease-in-out ' +
          (isLoading ? 'opacity-100' : 'opacity-0')
        }
      />
      {_image ? (
        <img
          ref={ref}
          src={_image.gatsbyImageData.images.fallback.src}
          srcSet={_image.gatsbyImageData.images.fallback.srcSet}
          sizes={_image.gatsbyImageData.images.fallback.sizes}
          alt={product.name}
          title={product.title}
          loading={loading}
          decoding="async"
          className={'w-full h-full aspect-square object-contain'}
        />
      ) : (
        <div className="w-full h-full bg-gray-200"></div>
      )}
    </Wrapper>
  )
}
CategoryImageSimplified.propTypes = {
  Wrapper: PropTypes.elementType,
  image: PropTypes.object,
  product: PropTypes.object,
  srcSizes: PropTypes.array,
  imgSizes: PropTypes.string,
  loading: PropTypes.string,
  className: PropTypes.string,
}

export default CategoryImageSimplified
