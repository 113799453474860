export function convertDateTimeString(inputDateTimeString) {

  const date = new Date(inputDateTimeString)
  
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') 
  const day = String(date.getDate()).padStart(2, '0')
  
  const formattedDate = `${month}/${day}/${year}`
  
  return formattedDate
}