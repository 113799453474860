import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { IconChevronLeft, IconChevronRight } from '../Icons'

const DotSlider = ({ className, dots = 4, children, showArrowsMobile }) => {
  const [currentIndex, setCurrentIndex] = useState(0) // Current "dot" or segment
  const totalSegments = dots || 4 // Total number of segments or dots
  const containerRef = useRef(null)
  const scrollDistance = useRef(0)
  //const [containerWidth, setContainerWidth] = useState(0) // State to store container width

  //const containerWidth = containerRef.current.offsetWidth

  useEffect(() => {
    // Ensure the component is mounted before attempting to access DOM properties
    if (containerRef.current) {
      const updateMeasurements = () => {
        if (!containerRef?.current) return
        const totalWidth = containerRef.current.scrollWidth
        const visibleWidth = containerRef.current.offsetWidth
        const totalScrollWidth = totalWidth - visibleWidth // The total scrollable width
        const segmentWidth = totalScrollWidth / (totalSegments - 1) // Assuming you want to divide the overflow into 3 segments

        scrollDistance.current = segmentWidth // Update scrollDistance for use in scrolling logic
        //setContainerWidth(visibleWidth) // Update state with current container width
      }
      const updateCurrentIndex = () => {
        if (!containerRef?.current) return
        const totalWidth = containerRef.current.scrollWidth
        const visibleWidth = containerRef.current.offsetWidth
        const totalScrollWidth = totalWidth - visibleWidth // The total scrollable width
        const segmentWidth = totalScrollWidth / (totalSegments - 1) // Assuming you want to divide the overflow into 3 segments; BTW - whyt 3??? it's totalSegments - 1??
        const currentSegment = Math.round(containerRef.current.scrollLeft / segmentWidth)

        setCurrentIndex(currentSegment)
      }
      updateMeasurements()
      // Optional: Recalculate on window resize
      const currentRef = containerRef.current
      window.addEventListener('resize', updateMeasurements, { passive: true })
      currentRef.addEventListener('scroll', updateCurrentIndex, { passive: true })

      // Cleanup listener on component unmount
      return () => {
        window.removeEventListener('resize', updateMeasurements)
        currentRef?.removeEventListener('scroll', updateCurrentIndex)
      }
    }
  }, [children, totalSegments]) // Recalculate when list changes

  // Adjusted scroll functions
  const goToNext = () => {
    const nextIndex = Math.min(currentIndex + 1, totalSegments - 1) // Ensure there are only 4 segments (0 to 3)
    scrollToSegment(nextIndex)
  }

  const goToPrevious = () => {
    const prevIndex = Math.max(currentIndex - 1, 0)
    scrollToSegment(prevIndex)
  }

  const scrollToSegment = index => {
    // Calculate the scroll position for the given segment index
    const newScrollPosition = scrollDistance.current * index
    if (containerRef.current) {
      containerRef.current.scrollTo({ left: newScrollPosition, behavior: 'smooth' })
    }
  }

  // Generate the "dots" for the navigation
  const dotsDivs = Array.from({ length: totalSegments }, (_, i) => i)

  return dots === 1 ? (
    children
  ) : (
    <div
      className={
        className + ' flex flex-row relative gap-3 justify-center items-center my-5 w-full'
      }
    >
      <div className="hidden sm:block absolute top-0 left-0 h-full w-12 md:w-10 bg-gradient-to-r from-white from-40% to-transparent  md:translate-x-9  z-10" />
      <div className="hidden sm:block absolute top-0 right-0 h-full w-12 md:w-10 bg-gradient-to-l from-white from-40% to-transparent md:-translate-x-9 z-10" />

      <button
        className={
          (showArrowsMobile
            ? ' absolute top-[50%] translate-y-[-50%] -left-4 flex '
            : 'hidden sm:flex ') +
          ' text-4xl aspect-square w-10 h-10 min-w-10 min-h-10 border-2 border-gray-400 text-gray-400 bg-white bg-opacity-70 hover:text-gray-700 hover:border-gray-700 hover:shadow-md justify-center items-center rounded-full duration-300 z-20'
        }
        onClick={goToPrevious}
      >
        <IconChevronLeft className="w-4 h-4 stroke-1" />
      </button>
      <section
        ref={containerRef}
        className="flex overflow-x-auto overflow-y-hidden scroll-smooth whitespace-nowrap gap-3 scrollbar-none w-full z-0 md:px-5 snap-x snap-mandatory *:snap-center"
      >
        {children}
      </section>
      <button
        className={
          (showArrowsMobile
            ? ' absolute top-[50%] translate-y-[-50%] -right-4 flex '
            : ' hidden sm:flex ') +
          '  text-4xl aspect-square w-10 h-10 min-w-10 min-h-10 border-2 border-gray-400 bg-white bg-opacity-70 text-gray-400 hover:text-gray-700 hover:border-gray-700 hover:shadow-md  justify-center items-center rounded-full duration-300 z-20 '
        }
        onClick={goToNext}
      >
        <IconChevronRight className="w-4 h-4 stroke-1" />
      </button>
      <div className="absolute -bottom-6 left-[50%] -translate-x-[50%] flex justify-center space-x-2 p-2 ">
        {dotsDivs.map((dot, index) => (
          <button
            key={dot}
            className={`h-2 w-2 rounded-full ${
              currentIndex === index ? 'bg-gray-400 md:bg-red-700' : 'bg-gray-300 md:bg-gray-400'
            }`}
            onClick={() => scrollToSegment(index)}
            aria-label={`Scroll to segment ${index + 1}`}
          />
        ))}
      </div>
    </div>
  )
}

DotSlider.defaultProps = {
  className: '',
  dots: 4,
}
DotSlider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  dots: PropTypes.number,
}

export default DotSlider
